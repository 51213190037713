<template>
  <div class="container">
    <template v-if="status === 1">
      <div>
        <img class="img" src="static/hj2023.jpg" />
        <!-- <img class="img" src="/static/hj/5.jpg" />
        <img class="img" src="/static/hj/grade7.jpg" /> -->
      </div>
      <!-- 暂停报名 -->
      <div @click="changeStatus(2)" class="apply-box" v-if="!hadPay">
        <div class="apply">立即报名</div>
      </div>
      <div @click="changeStatus(3)" class="apply-box" v-if="hadPay">
        <div class="apply">联系老师</div>
      </div>
    </template>

    <template v-if="status === 2">
      <div class="item-box">
        <div>活动：</div>
        <div>红军小学首届校园科技节活动</div>
      </div>

      <div class="item-box">
        <!-- <div>请选择您想要的上课时间。</div> -->
      </div>
    <nut-textinput
        v-model="name"
        label="学生姓名："
        placeholder="请输入学生姓名"
        :clearBtn="true"
        :disabled="false"
    />
    <nut-textinput
        v-model="phone"
        label="联系方式："
        placeholder="请输入家长手机号"
        :clearBtn="true"
        :disabled="false"
    />

    <nut-textinput
        v-model="grade"
        label="学生年级："
        placeholder="请输入年级"
        :clearBtn="true"
        :disabled="false"
    />

    <nut-textinput
        v-model="classInfo"
        label="学生班级："
        placeholder="请输入班级"
        :clearBtn="true"
        :disabled="false"
    />

    <!-- <div @click="isVisible = !isVisible" class="choose-item">
        <span class="title"><label>班级：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ grade }}</span>
          <img src="/static/unfold.png" style="width:1rem; height:1rem; margin-left: 5px;" />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="isVisible"
        :menu-items="gradeItems"
        cancelTxt="取消"
        @close="isVisible = !isVisible"
        @choose="chooseGrade"
    ></nut-actionsheet> -->
    <div class="item-box">
        <div>活动时间：</div>
        <div>11 月 25 日</div>
    </div>

    <div @click="weekIsVisible=!weekIsVisible" class="choose-item">
        <span class="title"><label>选择场次：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ week }}</span>
          <img src="/static/unfold.png" style="width:1rem; height:1rem; margin-left: 5px;"  />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="weekIsVisible"
        :menu-items="weekItems"
        cancelTxt="取消"
        @close="weekIsVisible=!weekIsVisible"
        @choose="chooseWeek"
    ></nut-actionsheet>

      <div @click="pay" class="pay-box">
        <!-- <div style="color: #f76627;font-weight: bolder;">¥ 480</div> -->
        <div class="pay">提交</div>
      </div>
    </template>
    <template v-if="status ===3">
      <img src="/static/suc.png" class="success" />
      <div style="font-size: 1.3rem;font-weight: bolder;">已经预约</div>
      <div style="margin: 1.5rem 0;">建议您长按下面的二维码添加小创，获取最新消息。</div>
      <img src="/static/qr.jpeg" style="width: 10rem; height: 10rem;" />
      <template v-if="info">
        <div>{{ info.name }}</div>
        <div v-if="info.grade">{{ info.grade }}</div>
        <div>11 月 25 日 {{ info.week }}</div>
        <!-- <div v-if="info.week.indexOf('星期六') !== -1">9月4日{{ info.week }}上课</div>
        <div v-if="info.week.indexOf('星期日') !== -1">9月5日{{ info.week }}上课</div> -->
      </template>

      <div @click="cancelReserve" class="pay-box">
        <!-- <div style="color: #f76627;font-weight: bolder;">¥ 480</div> -->
        <div class="pay">取消预约</div>
      </div>
    </template>
    <nut-popup :style="{ padding: '30px 50px' }" v-model="show">请填完信息</nut-popup>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  data () {
    return {
      isVisible: false,
      classIsVisible: false,
      weekIsVisible: false,
      timeIsVisible: false,
      weekItems: [{
        name: '第一场 9:00-10:30',
        value: 1
      }, {
        name: '第二场 10:30-12:00',
        value: 2
      }
      ],
      gradeItems: [{
        name: '3年级1班',
        value: 3
      }, {
        name: '3年级2班',
        value: 4
      }, {
        name: '3年级3班',
        value: 5
      }, {
        name: '3年级4班',
        value: 3
      }, {
        name: '3年级5班',
        value: 4
      }, {
        name: '3年级6班',
        value: 5
      }, {
        name: '3年级7班',
        value: 4
      }, {
        name: '3年级8班',
        value: 5
      }, {
        name: '4年级1班',
        value: 3
      }, {
        name: '4年级2班',
        value: 4
      }, {
        name: '4年级3班',
        value: 5
      }, {
        name: '4年级4班',
        value: 3
      }, {
        name: '4年级5班',
        value: 4
      }, {
        name: '5年级1班',
        value: 4
      }],
      timeItems: [{
        name: '17:30-19:00',
        value: 1
      }],
      classItems: [],
      grade: '',
      week: '',
      classInfo: '',
      time: '请选择',
      status: 1,
      name: '',
      phone: '',
      address: '',
      date: 1,
      show: false,
      hadPay: false,
      tp: 0,
      info: {},
      fetching: false
    }
  },
  methods: {
    chooseClass (item) {
      this.classInfo = item.name
    },
    chooseTime (item) {
      this.time = item.name
    },
    chooseGrade (item) {
      console.log(item)
      this.grade = item.name
      this.classInfo = '请选择'
      if (item.name === '三年级') {
        this.classItems = [{
          name: '1班',
          value: 1
        }, {
          name: '2班',
          value: 2
        }, {
          name: '3班',
          value: 3
        }, {
          name: '4班',
          value: 4
        }, {
          name: '5班',
          value: 5
        }, {
          name: '6班',
          value: 6
        }, {
          name: '7班',
          value: 7
        }, {
          name: '8班',
          value: 8
        }]
      } else if (item.name === '四年级') {
        this.classItems = [{
          name: '1班',
          value: 1
        }, {
          name: '2班',
          value: 2
        }, {
          name: '3班',
          value: 3
        }, {
          name: '4班',
          value: 4
        }, {
          name: '5班',
          value: 5
        }]
      }
    },
     getRemainingQuota (str) {
      // 使用正则表达式匹配"剩余名额："后面的数字
      const regex = /剩余名额：(\d+)/;
      const match = str.match(regex);

      // 如果找到匹配项，返回第一个捕获组中的数字（即匹配的数字）
      if (match && match[1]) {
        return parseInt(match[1], 10); // 将匹配的字符串转换为数字
      }

      return 0; // 如果没有找到匹配项，返回 null
    },
    chooseWeek (item) {
      const remain = parseInt(this.getRemainingQuota(item.name))
      // const isMinus = item.name.indexOf('-') !== -1
      // 剩余名额有负数或者为零
      if (remain <= 0) {
        return
      }
      const parts = item.name?.split('(');
      this.week = parts[0].trim() || ''
    },

    pay () {
      if (this.fetching) {
        return
      }

      const openid = localStorage.getItem('openid')

      if (!openid) {
        this.show = true
        return
      }

      if (!this.name || this.phone.length !== 11 || !this.grade || !this.classInfo || !this.week) {
        this.show = true
        return
      }

      this.fetching = true
      axios({
        url: 'https://shop.actuedu.com/web/add_reserve',
        method: 'post',
        data: {
          openid,
          name: this.name,
          phone: this.phone,
          grade: this.grade,
          classInfo: this.classInfo,
          week: this.week
        }
      })
        .then(response => {
          console.log(response)
          this.fetching = false
          const json = response.data && response.data
          console.log(json)
          if (json.ret === 1000) {
            this.status = 3
          }
        })
        .catch(e => {
          console.log(e)
          this.fetching = false
        })
      // eslint-disable
    },

    cancelReserve () {
      const openid = localStorage.getItem('openid')
      if (!openid) {
        return
      }
      axios({
        url: 'https://shop.actuedu.com/web/cancel_reserve',
        method: 'post',
        data: {
          openid
        }
      })
        .then(response => {
          console.log(response)
          const isCancel = response.data && response.data.data
          if (isCancel === 1) {
            location.reload()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },

    changeStatus (status) {
      const openid = localStorage.getItem('openid')
      if (status === 2 && !openid) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx94da9e1b3126380e&redirect_uri=https://shop.actuedu.com/hongjun&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect'
        return
      }
      this.status = status
    }
  },
  created () {
    document.title = '红军小学首届校园科技节活动'
    const openid = localStorage.getItem('openid')
    axios({
      url: 'https://shop.actuedu.com/web/had_reserve',
      method: 'post',
      data: {
        openid
      }
    })
      .then(response => {
        console.log(response)
        const json = response.data && response.data.data
        const had = json.had
        this.info = json.info
        console.log(json)
        if (had) {
          this.status = 3
        }
      })
      .catch(e => {
        console.log(e)
      })

    axios({
      url: 'https://shop.actuedu.com/web/get_reserve_count_new',
      method: 'post',
      data: {
      }
    })
      .then(response => {
        console.log(response)
        const count = response.data && response.data.count
        // that.info = json
        console.log('json: ', count)
        let weekItemsRe = []
        const total = 50
        this.weekItems.forEach((item, i) => {
          // 80个
          if (item.name === '第一场 9:00-10:30') {
            item.remain = total - count.w1
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '第二场 10:30-12:00') {
            item.remain = total - count.w2
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期六14:00-15:00') {
            item.remain = total - count.w3
            item.name = `${item.name}(剩余名额：${item.remain})`
          }
          // 120个
          if (item.name === '星期六15:30-16:30') {
            item.remain = total - count.w4
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期六17:00-18:00') {
            item.remain = total - count.w5
            item.name = `${item.name}(剩余名额：${item.remain})`
          }


          if (item.name === '星期日09:00-10:00') {
            item.remain = total - count.w6
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期日10:30-11:30') {
            item.remain = total - count.w7
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期日14:00-15:00') {
            item.remain = total - count.w8
            item.name = `${item.name}(剩余名额：${item.remain})`
          }
          // 120个
          if (item.name === '星期日15:30-16:30') {
            item.remain = total - count.w9
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期日17:00-18:00') {
            item.remain = total - count.w10
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          weekItemsRe.push(item)
        })

        // console.log(this.weekItems)
        // this.weekItems = this.weekItems.filter(item => !item.is_over)
        // this.weekItems = [...this.weekItemsRe]
      })
      .catch(e => {
        console.log(e)
      })

    axios({
      url: 'https://shop.actuedu.com/web/get_signature',
      method: 'post',
      data: {
        url: 'https://shop.actuedu.com/reserve'
      }
    })
      .then(response => {
        console.log(response)
        const data = response.data && response.data.data
        window.wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateTimelineShareData', 'updateAppMessageShareData']
        })

        window.wx.ready(function () {
          window.wx.updateAppMessageShareData({
            title: '红军小学首届校园科技节活动',
            desc: '开始报名啦',
            link: 'https://shop.actuedu.com/reserve',
            imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
            success: function () {
              // alert('成功')
            },
            fail: function () {
              // alert('失败')
            }
          })
          window.wx.onMenuShareAppMessage({
            title: '红军小学首届校园科技节活动',
            desc: '开始报名啦',
            link: 'https://shop.actuedu.com/reserve',
            imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
            type: 'link',
            dataUrl: null,
            success: function () {
            // 用户点击了分享后执行的回调函数
            }
          })
        })
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding-bottom: 8vh;
  padding: 10px;
}

.choose-item {
  width: 95vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  height: 7vh;
}

.img {
  width: 100vw;
  box-sizing: border-box;
}

.apply-box, .pay-box {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: fixed;
  bottom: 0;
  height: 9vh;
  border-top: 1px solid #f2f2f2;
}

.apply {
  width: 80vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
}

.pay {
  width: 75vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
  /* margin-left: 2rem; */
}

.nut-textinput {
  margin: 10px 0;
}

.item-box {
  display: flex;
  align-items: center;
}

.success {
  width: 6rem;
  height: 6rem;
  box-sizing: border-box;
  margin-top: 2rem;
}

.tp-box {
  display: flex;
  flex-wrap: wrap;
  width: 60vw;
  margin: 20px 0;
}
</style>
