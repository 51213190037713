<template>
  <div class="container">
    <template v-if="status === 1">
      <div class="media-container">
        <img class="full-width-img" src="https://cdn.runkodo.com/image/20240902%20%E4%B8%89%E5%B9%B4%E7%BA%A7-tuya.jpg" alt="课程图片" />
        <video class="video" src="https://cdn.runkodo.com/image/20240902video.mp4" controls muted loop autoplay></video>
      </div>

      <div @click="changeStatus(2)" class="apply-box" v-if="!hadPay">
        <div class="apply">立即报名</div>
      </div>
      <div @click="changeStatus(3)" class="apply-box" v-if="hadPay">
        <div class="apply">联系老师</div>
      </div>
    </template>
    <template v-if="status === 2">
      <div class="item-box">
        <div>学校年级：</div>
        <div>2024年秋季红军小学三年级</div>
      </div>

      <!-- <div class="item-box">
        <div>上课时间：</div>
        <div>16:30-18:10</div>
      </div> -->

      <!-- <div class="item-box">
        <div>报名截止时间：</div>
        <div>2023年08月31日</div>
      </div> -->


    <nut-textinput
        v-model="name"
        label="学生姓名："
        placeholder="请输入学生姓名"
        :clearBtn="true"
        :disabled="false"
    />
    <nut-textinput
        v-model="grade"
        label="学生班级："
        placeholder="请输入学生班级"
        :clearBtn="true"
        :disabled="false"
    />
    <nut-textinput
        v-model="phone"
        label="手机号码："
        placeholder="请输入联系方式"
        :clearBtn="true"
        :disabled="false"
    />
    <nut-textinput
        v-model="address"
        label="联系地址："
        placeholder="请输入联系地址"
        :clearBtn="true"
        :disabled="false"
    />
      <div class="item-box">
        <!-- <div>上课星期：</div> -->

      <div @click="isVisible = !isVisible" class="choose-item">
          <span class="title"><label>上课时间：</label></span>
          <div style="display: flex; align-items: center;">
            <span class="option" style="color: #9a9a9a;">{{ week }}</span>
            <img src="static/unfold.png" style="width:1rem; height:1rem; margin-left: 5px;" />
          </div>
      </div>
  
    <nut-actionsheet
        :is-visible="isVisible"
        :menu-items="weekItems"
        @close="isVisible = !isVisible"
        @choose="chooseWeek"
        cancelTxt="取消"
    ></nut-actionsheet>
  
      <!-- <nut-radio
        v-model="date"
        :label="1"
      >周一
      </nut-radio>
      <nut-radio
        v-model="date"
        :label="2"
      >周二
      </nut-radio>
      <nut-radio
        v-model="date"
        :label="3"
      >周三
      </nut-radio> -->
    </div>

      <!-- <div class="item-box" style="margin-top: 10px;">
        <div>课程时间：</div>
      <nut-radio
        v-model="time"
        :label="1"
      > 上午9:00-12:00
      </nut-radio>
      <nut-radio
        v-model="time"
        :label="2"
      >下午14:30-17:30
      </nut-radio>
    </div> -->
      <div @click="pay" class="pay-box">
      <!-- <div  class="pay-box"> -->
        <div style="color: #f76627;font-weight: bolder;">¥ 485</div>
        <div class="pay">支付</div>
        <!-- <div style="margin-left: 5px;">报名已结束</div> -->
      </div>
    </template>
    <template v-if="status ===3">
      <img src="/static/suc.png" class="success" />
      <div style="font-size: 1.3rem;font-weight: bolder;">支付成功</div>
      <div style="margin: 1.5rem 0;">请长按下面的二维码添加小创，获取最新的上课信息。</div>
      <img src="/static/qr.jpeg" style="width: 10rem; height: 10rem;" />
      <template v-if="info.had">
        <div>{{ info.name }}</div>
        <div v-if="info.tp">{{ info.tp }}</div>
        <div>每 {{ info.date }} 上课</div>
        <!-- <div>{{ info.time }}</div> -->
      </template>
    </template>
    <nut-popup :style="{ padding: '30px 50px' }" v-model="show">请填完信息再支付</nut-popup>
    <!-- <div v-if="status === 1"><a href="https://mp.weixin.qq.com/s/f8PznJqOP-ASjWxTsH0SeA">点击了解暑期创造营竞赛</a></div> -->
  </div>
</template>

<script>
import axios from 'axios'
import config from '../config/index.json'
export default {
  name: 'HelloWorld',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      status: 1,
      name: '',
      grade: '',
      phone: '',
      address: '',
      time: 11,
      show: false,
      hadPay: false,
      tp: 11,
      info: {},
      isVisible: false,
      week: '请选择',
      weekItems: [{
        name: '周一',
        value: 1
      }, {
        name: '周二',
        value: 2
      }, {
        name: '周三',
        value: 3
      }, {
        name: '周四',
        value: 4
      }, {
        name: '周五',
        value: 5
      }]
    }
  },
  methods: {
    pay () {
      const that = this
      console.log(this.name, this.grade, this.phone, this.address, this.date, this.time)
      const openid = localStorage.getItem('openid')
      if (!this.name || !this.grade || !this.phone || !this.address || !this.time || this.week === '请选择') {
        this.show = true
        return
      }

      let timeRe = '待定'
      if (this.time === 1) {
        timeRe = '上午9:00-12:00'
      } else if (this.time === 2) {
        timeRe = '下午14:30-17:30'
      }

      let dateRe = '周一'
      if (this.week === '周一') {
        dateRe = '周一'
      } else if (this.date === '周二') {
        dateRe = '周二'
      } else if (this.date === '周三') {
        dateRe = '周三'
      }

      let tpRe = '劳动创新课程（三年级）'
      if (this.tp === 1) {
        tpRe = '创意创新课程（三年级）'
      } else if (this.tp === 2) {
        tpRe = '汽车工程师（三、四年级）'
      } else if (this.tp === 3) {
        tpRe = '房屋设计师（三、四年级）'
      }
      axios({
        url: 'https://shop.actuedu.com/web/init_pay',
        method: 'post',
        data: {
          name: this.name,
          school: '2024年秋季红军小学三年级',
          grade: this.grade,
          phone: this.phone,
          address: this.address,
          date: this.week.slice(0, 2),
          time: timeRe,
          tp: '爱创空间科创课',
          openid
        }
      })
        .then(response => {
          console.log(response)
          const json = response.data && response.data.data
          console.log(json)
          window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              'appId': json.appId,
              'timeStamp': json.timeStamp,
              'nonceStr': json.nonceStr,
              'package': json.package,
              'signType': 'MD5',
              'paySign': json.paySign
            },
            (res) => {
              if (res.err_msg === 'get_brand_wcpay_request:ok') {
                alert('支付成功,请稍后查询,正在保存订票信息，如有疑问,请联系管理员..')
                that.status = 3
              } else {
                console.log('关闭了微信支付')
              }
            })
        })
        .catch(e => {
          console.log(e)
        })
      // eslint-disable
    },
    changeStatus (status) {
      const openid = localStorage.getItem('openid')
      if (status === 2 && !openid) {
        location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.appid}&redirect_uri=https://shop.actuedu.com/hjgrade3&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect`
        return
      }
      this.status = status
    },

    chooseWeek (item) {
      const remain = parseInt(item.name.match(/\d+/)[0])
      const isMinus = item.name.indexOf('-') !== -1
      // 剩余名额有负数或者为零
      if (isMinus || remain <= 0) {
        return
      }

      this.week = item.name.slice(0, 2)

      console.log(item, this.week)
    }
  },
  created () {
    document.title = '红军小学三年级爱创空间科创课程开始报名了'
    const openid = localStorage.getItem('openid')
    const that = this
    axios({
      url: 'https://shop.actuedu.com/web/had_pay',
      method: 'post',
      data: {
        school: '2024年秋季红军小学三年级',
        openid
      }
    })
      .then(response => {
        console.log(response)
        const json = response.data && response.data.data
        this.hadPay = json.had
        that.info = json
        console.log(json)
      })
      .catch(e => {
        console.log(e)
      })

    axios({
      url: 'https://shop.actuedu.com/web/get_config',
      method: 'post',
      data: {
        url: 'https://shop.actuedu.com/hjgrade3'
      }
    })
      .then(response => {
        console.log(response)
        const json = response.data && response.data.data
        console.log(json)
        window.wx.config({
          debug: false,
          appId: config.appid,
          timestamp: json.timestamp,
          nonceStr: json.noncestr,
          signature: json.signature,
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage']
        })
        window.wx.ready(function () {
          window.wx.updateAppMessageShareData({
            title: '红军小学三年级爱创空间科创课程开始报名了',
            desc: '开始报名了',
            link: 'https://shop.actuedu.com/hjgrade3',
            imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
            success: function () {
              // alert('成功')
            },
            fail: function () {
              // alert('失败')
            }
          })
          window.wx.onMenuShareAppMessage({
            title: '红军小学三年级劳动创新课程开始报名了',
            desc: '开始报名了',
            link: 'https://shop.actuedu.com/hjgrade3',
            imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
            type: 'link',
            dataUrl: null,
            success: function () {
            // 用户点击了分享后执行的回调函数
            }
          })
        })
      })
      .catch(e => {
        console.log(e)
      })



    axios({
      url: 'https://shop.actuedu.com/web/get_reserve_count',
      method: 'post',
      data: {
        school: '2024年秋季红军小学三年级'
      }
    })
      .then(response => {
        console.log(response)
        const count = response.data && response.data.count
        // that.info = json
        const total = 30
        console.log('json: ', count)
        let weekItemsRe = []
        this.weekItems.forEach((item, i) => {
          if (item.name === '周一') {
            item.remain = total - count.w1
            // item.remain = 0
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          if (item.name === '周二') {
            item.remain = total - count.w2
            // item.remain = 0
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          if (item.name === '周三') {
            item.remain = total - count.w3
            // item.remain = 0
            item.name = `${item.name}(剩余名额：${item.remain})`
          }


          if (item.name === '周四') {
            item.remain = total - count.w4
            // item.remain = 0
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          if (item.name === '周五') {
            item.remain = total - count.w5
            // item.remain = 0
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          weekItemsRe.push(item)
        })

        // console.log(this.weekItems)
        // this.weekItems = this.weekItems.filter(item => !item.is_over)
        this.weekItems = [...this.weekItemsRe]
      })
      .catch(e => {
        console.log(e)
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding-bottom: 8vh;
  padding: 15px;
  box-sizing: border-box;
}

.img {
  width: 100vw;
  box-sizing: border-box;
}

.media-container {
  width: 100%;
  overflow: hidden;
  padding-bottom: 150px;
}

.full-width-img {
  width: 100%;
  height: auto;
  display: block;
}

.video {
  width: 100%;
  height: 200px; /* 或者其他适合的固定高度 */
  object-fit: cover;
  margin-top: 10px; /* 给视频和图片之间添加一些间距 */
}

.apply-box, .pay-box {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: fixed;
  bottom: 0;
  height: 9vh;
  border-top: 1px solid #f2f2f2;
}

.apply {
  width: 80vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
}

.pay {
  width: 60vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
  margin-left: 2rem;
}

.nut-textinput {
  margin: 10px 0;
}

.item-box {
  display: flex;
  align-items: center;
}

.success {
  width: 6rem;
  height: 6rem;
  box-sizing: border-box;
  margin-top: 2rem;
}

.tp-box {
  display: flex;
  flex-wrap: wrap;
  width: 60vw;
  margin: 20px 0;
}

.choose-item {
  width: 95vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  height: 7vh;
}
</style>
