<template>
  <div class="container">
    <template v-if="status === 1">
      <div class="item-box">
        <div>请选择您想要的上课时间。</div>
      </div>
    <nut-textinput
        v-model="name"
        label="姓名："
        placeholder="请输入学生姓名"
        :clearBtn="true"
        :disabled="false"
    />
    <div @click="isVisible = !isVisible" class="choose-item">
        <span class="title"><label>年级：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ grade }}</span>
          <img src="/static/unfold.png" style="width:3.2vh; height:3.2vh;" />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="isVisible"
        :menu-items="gradeItems"
        cancelTxt="取消"
        @close="isVisible = !isVisible"
        @choose="chooseGrade"
    ></nut-actionsheet>

    <div @click="classIsVisible=!classIsVisible" class="choose-item" v-if="grade==='三年级' || grade==='四年级'">
        <span class="title"><label>班级：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ classInfo }}</span>
          <img src="/static/unfold.png" style="width:3.2vh; height:3.2vh;" />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="classIsVisible"
        :menu-items="classItems"
        cancelTxt="取消"
        @close="classIsVisible=!classIsVisible"
         @choose="chooseClass"
    ></nut-actionsheet>

    <div @click="weekIsVisible=!weekIsVisible" class="choose-item">
        <span class="title"><label>星期：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ week }}</span>
          <img src="/static/unfold.png" style="width:3.2vh; height:3.2vh;" />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="weekIsVisible"
        :menu-items="weekItems"
        cancelTxt="取消"
        @close="weekIsVisible=!weekIsVisible"
        @choose="chooseWeek"
    ></nut-actionsheet>

    <div @click="timeIsVisible=!timeIsVisible" class="choose-item" v-if="week!=='请选择'">
        <span class="title"><label>时间：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ time }}</span>
          <img src="/static/unfold.png" style="width:3.2vh; height:3.2vh;" />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="timeIsVisible"
        :menu-items="timeItems"
        cancelTxt="取消"
        @close="timeIsVisible=!timeIsVisible"
         @choose="chooseTime"
    ></nut-actionsheet>

      <div @click="pay" class="pay-box">
        <!-- <div style="color: #f76627;font-weight: bolder;">¥ 480</div> -->
        <div class="pay">提交</div>
      </div>
    </template>
    <template v-if="status ===2">
      <img src="/static/suc.png" class="success" />
      <div style="font-size: 1.3rem;font-weight: bolder;">已经填写</div>
      <div style="margin: 1.5rem 0;">请长按下面的二维码添加小创，获取最新的上课信息。</div>
      <img src="/static/qr.jpeg" style="width: 10rem; height: 10rem;" />
      <!-- <template v-if="info.had">
        <div>{{ info.name }}</div>
        <div v-if="info.tp">{{ info.tp }}</div>
        <div>{{ info.date }}</div>
        <div>{{ info.time }}</div>
      </template> -->
    </template>
    <nut-popup :style="{ padding: '30px 50px' }" v-model="show">请填完信息</nut-popup>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  data () {
    return {
      isVisible: false,
      classIsVisible: false,
      weekIsVisible: false,
      timeIsVisible: false,
      gradeItems: [{
        name: '三年级',
        value: 3
      }, {
        name: '四年级',
        value: 4
      }, {
        name: '五年级',
        value: 5
      }],
      timeItems: [{
        name: '17:30-19:00',
        value: 1
      }],
      weekItems: [{
        name: '周一',
        value: 1
      }, {
        name: '周二',
        value: 2
      }, {
        name: '周三',
        value: 3
      }, {
        name: '周四',
        value: 4
      }, {
        name: '周五',
        value: 5
      }, {
        name: '周六',
        value: 6
      }, {
        name: '周日',
        value: 7
      }],
      classItems: [],
      grade: '请选择',
      week: '请选择',
      classInfo: '请选择',
      time: '请选择',
      status: 1,
      name: '',
      phone: '',
      address: '',
      date: 1,
      show: false,
      hadPay: false,
      tp: 0,
      info: {},
      fetching: false
    }
  },
  methods: {
    chooseClass (item) {
      this.classInfo = item.name
    },
    chooseTime (item) {
      this.time = item.name
    },
    chooseGrade (item) {
      console.log(item)
      this.grade = item.name
      this.classInfo = '请选择'
      if (item.name === '三年级') {
        this.classItems = [{
          name: '1班',
          value: 1
        }, {
          name: '2班',
          value: 2
        }, {
          name: '3班',
          value: 3
        }, {
          name: '4班',
          value: 4
        }, {
          name: '5班',
          value: 5
        }, {
          name: '6班',
          value: 6
        }, {
          name: '7班',
          value: 7
        }, {
          name: '8班',
          value: 8
        }]
      } else if (item.name === '四年级') {
        this.classItems = [{
          name: '1班',
          value: 1
        }, {
          name: '2班',
          value: 2
        }, {
          name: '3班',
          value: 3
        }, {
          name: '4班',
          value: 4
        }, {
          name: '5班',
          value: 5
        }]
      }
    },

    chooseWeek (item) {
      this.week = item.name
      this.time = '请选择'
      if (item.name === '周六' || item.name === '周日') {
        this.timeItems = [{
          name: '8:30-10:00',
          value: 1
        }, {
          name: '10:30-12:00',
          value: 2
        }, {
          name: '14:00-15:30',
          value: 3
        }, {
          name: '15:45-17:15',
          value: 4
        }, {
          name: '17:30-19:00',
          value: 5
        }]
      } else {
        this.timeItems = [{
          name: '17:30-19:00',
          value: 1
        }]
      }
    },

    pay () {
      if (this.fetching) {
        return
      }

      const openid = localStorage.getItem('openid')
      if (this.grade === '五年级') {
        this.classInfo = '固定一个班级'
      }

      if (!this.name || this.grade === '请选择' || this.week === '请选择' || this.time === '请选择' || this.classInfo === '请选择') {
        this.show = true
        return
      }
      console.log(this.name, this.grade, this.week, this.time, this.classInfo)
      this.fetching = true
      axios({
        url: 'https://shop.actuedu.com/web/choose_time',
        method: 'post',
        data: {
          openid,
          name: this.name,
          grade: this.grade,
          week: this.week,
          time: this.time,
          classInfo: this.classInfo
        }
      })
        .then(response => {
          console.log(response)
          this.fetching = false
          const json = response.data && response.data
          console.log(json)
          if (json.ret === 1000) {
            this.status = 2
          }
        })
        .catch(e => {
          console.log(e)
          this.fetching = false
        })
      // eslint-disable
    },
    changeStatus (status) {
      const openid = localStorage.getItem('openid')
      if (status === 2 && !openid) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx94da9e1b3126380e&redirect_uri=https://shop.actuedu.com/hongjun&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect'
        return
      }
      this.status = status
    }
  },
  created () {
    document.title = '红军小学课后服务劳动创新课约课链接'
    const openid = localStorage.getItem('openid')
    axios({
      url: 'https://shop.actuedu.com/web/had_choose',
      method: 'post',
      data: {
        openid
      }
    })
      .then(response => {
        console.log(response)
        const json = response.data && response.data.data
        const had = json.had
        // that.info = json
        console.log(json)
        if (had) {
          this.status = 2
        }
      })
      .catch(e => {
        console.log(e)
      })
    window.wx.ready(function () {
      window.wx.updateAppMessageShareData({
        title: '红军小学课后服务劳动创新课约课链接',
        desc: '选择上课时间',
        link: 'https://shop.actuedu.com/hongjun',
        imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
        success: function () {
          // alert('成功')
        },
        fail: function () {
          // alert('失败')
        }
      })
      window.wx.onMenuShareAppMessage({
        title: '红军小学课后服务劳动创新课约课链接',
        desc: '选择上课时间',
        link: 'https://shop.actuedu.com/hongjun',
        imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
        type: 'link',
        dataUrl: null,
        success: function () {
        // 用户点击了分享后执行的回调函数
        }
      })
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding-bottom: 8vh;
}

.choose-item {
  width: 95vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  height: 6vh;
}

.img {
  width: 100vw;
  box-sizing: border-box;
}

.apply-box, .pay-box {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: fixed;
  bottom: 0;
  height: 9vh;
  border-top: 1px solid #f2f2f2;
}

.apply {
  width: 80vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
}

.pay {
  width: 60vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
  /* margin-left: 2rem; */
}

.nut-textinput {
  margin: 10px 0;
}

.item-box {
  display: flex;
  align-items: center;
}

.success {
  width: 6rem;
  height: 6rem;
  box-sizing: border-box;
  margin-top: 2rem;
}

.tp-box {
  display: flex;
  flex-wrap: wrap;
  width: 60vw;
  margin: 20px 0;
}
</style>
