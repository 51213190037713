import Vue from 'vue/dist/vue.esm.js'
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import Houji from '@/components/Houji'
import Time from '@/components/Time'
import Reserve from '@/components/Reverse'
import Hongjun2 from '@/components/Hongjungrade2'
import Hongjun3 from '@/components/Hongjungrade3'
import Hongjun4 from '@/components/Hongjungrade4'
import Hongjun5 from '@/components/Hongjungrade5'
import Reverse1 from '@/components/Reverse1'
import config from '../config/index.json'
// import Weifeng from '@/components/Weifeng'
import axios from 'axios'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'HelloWorld',
      component: HelloWorld
    }, {
      path: '/houji2021',
      name: 'Houji',
      component: Houji
    }, {
      path: '/hjgrade3',
      name: 'Hongjun3',
      component: Hongjun3
    }, {
      path: '/hjgrade2',
      name: 'Hongjun2',
      component: Hongjun2
    }, {
      path: '/hjgrade4',
      name: 'hongjun4',
      component: Hongjun4
    }, {
      path: '/hjgrade5',
      name: 'hongjun5',
      component: Hongjun5
    }, {
      path: '/choose_time',
      name: 'ChooseTime',
      component: Time
    }, {
      path: '/reserve',
      name: 'Reverse1',
      component: Reverse1
    }
  ]
})

const isAuthenticated = localStorage.getItem('openid')
// const month = localStorage.getItem('month')
const appid = config.appid
const domain = 'https://shop.actuedu.com'
// 临时浏览器测试参数
let temp = true

router.beforeEach((to, from, next) => {
  const code = to.query.code
  console.log(to.path)
  // 清除2021年2月24日之前保存的openid
  if (!localStorage.getItem('clearBeforeOpenid2')) {
    localStorage.clear()
    localStorage.setItem('clearBeforeOpenid2', 1)
    location.reload()
  }

  if (temp && code && !isAuthenticated) {
    axios({
      url: 'https://shop.actuedu.com/web/login',
      method: 'post',
      data: {
        code
      }
    })
      .then(response => {
        console.log(response)
        const openid = response.data && response.data.data
        if (openid) {
          localStorage.setItem('openid', openid)
          location.href = domain + to.path
        } else {
          const url = domain + to.fullPath
          // console.log(domain + to.fullPath, to.name, to.query.code)
          location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect`
        }
      })
      .catch(e => {
        const url = domain + to.fullPath
        // console.log(domain + to.fullPath, to.name, to.query.code)
        location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect`
        console.log(e)
      })
  }
  if (temp && to.name !== 'Login' && !isAuthenticated && !code) {
    // location.href = 'https://baidu.com'
    const url = domain + to.fullPath
    console.log(domain + to.fullPath, to.name, to.query.code)
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect`
  } else next()
})

export default router
