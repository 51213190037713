<template>
  <div class="container">
    <template v-if="status === 1">
      <div class="item-box">
        <!-- <div>请选择您想要的上课时间。</div> -->
      </div>
    <nut-textinput
        v-model="name"
        label="学生姓名："
        placeholder="请输入学生姓名"
        :clearBtn="true"
        :disabled="false"
    />
    <nut-textinput
        v-model="phone"
        label="家长联系方式："
        placeholder="请输入家长手机号"
        :clearBtn="true"
        :disabled="false"
    />
    <div @click="isVisible = !isVisible" class="choose-item">
        <span class="title"><label>日期：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ grade }}</span>
          <img src="/static/unfold.png" style="width:1rem; height:1rem; margin-left: 5px;" />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="isVisible"
        :menu-items="gradeItems"
        cancelTxt="取消"
        @close="isVisible = !isVisible"
        @choose="chooseGrade"
    ></nut-actionsheet>

    <div @click="weekIsVisible=!weekIsVisible" class="choose-item">
        <span class="title"><label>上课时间：</label></span>
        <div style="display: flex; align-items: center;">
          <span class="option" style="color: #9a9a9a;">{{ week }}</span>
          <img src="/static/unfold.png" style="width:1rem; height:1rem; margin-left: 5px;"  />
        </div>
    </div>
    <nut-actionsheet
        :is-visible="weekIsVisible"
        :menu-items="weekItems"
        cancelTxt="取消"
        @close="weekIsVisible=!weekIsVisible"
        @choose="chooseWeek"
    ></nut-actionsheet>

      <div @click="pay" class="pay-box">
      <!-- <div class="pay-box"> -->
        <!-- <div style="color: #f76627;font-weight: bolder;">¥ 485</div>
        <div class="pay">已截止报名</div>
      </div> -->
        <div class="pay">预约</div>
      </div>
    </template>
    <template v-if="status ===2">
      <img src="/static/suc.png" class="success" />
      <div style="font-size: 1.3rem;font-weight: bolder;">已经报名</div>
      <div style="margin: 1.5rem 0;">建议您长按下面的二维码添加小创，获取最新消息。</div>
      <img src="/static/qr.jpeg" style="width: 10rem; height: 10rem;" />
      <template v-if="info">
        <div>{{ info.name }}</div>
        <div v-if="info.grade">{{ info.grade }}</div>
        <!-- <div>{{ info.time }}</div> -->
        <div>每周 {{ info.time }} 上课</div>
      </template>

      <!-- <div @click="cancelReserve" class="pay-box"> -->
        <!-- <div style="color: #f76627;font-weight: bolder;">¥ 480</div> -->
        <!-- <div class="pay">取消预约</div> -->
      <!-- </div> -->
    </template>
    <nut-popup :style="{ padding: '30px 50px' }" v-model="visible">请填完信息</nut-popup>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'HelloWorld',
  data () {
    return {
      isVisible: false,
      classIsVisible: false,
      weekIsVisible: false,
      timeIsVisible: false,
      weekItems: [{
        name: '星期一',
        value: 1
      }, {
        name: '星期二',
        value: 2
      }, {
        name: '星期三',
        value: 3
      }, {
        name: '星期四',
        value: 4
      }, {
        name: '星期五',
        value: 5
      }
      ],
      gradeItems: [{
        name: '3年级1班',
        value: 3
      }, {
        name: '3年级2班',
        value: 4
      }, {
        name: '3年级3班',
        value: 5
      }, {
        name: '3年级4班',
        value: 3
      }, {
        name: '3年级5班',
        value: 4
      }, {
        name: '3年级6班',
        value: 5
      }, {
        name: '3年级7班',
        value: 4
      }, {
        name: '3年级8班',
        value: 5
      }, {
        name: '4年级1班',
        value: 3
      }, {
        name: '4年级2班',
        value: 4
      }, {
        name: '4年级3班',
        value: 5
      }, {
        name: '4年级4班',
        value: 3
      }, {
        name: '4年级5班',
        value: 4
      }, {
        name: '5年级1班',
        value: 4
      }],
      timeItems: [{
        name: '17:30-19:00',
        value: 1
      }],
      classItems: [],
      grade: '请选择',
      week: '请选择',
      classInfo: '请选择',
      time: '请选择',
      status: 1,
      name: '',
      phone: '',
      address: '',
      date: 1,
      show: false,
      hadPay: false,
      tp: 0,
      info: {},
      fetching: false
    }
  },
  methods: {
    chooseClass (item) {
      this.classInfo = item.name
    },
    chooseTime (item) {
      this.time = item.name
    },
    chooseGrade (item) {
      console.log(item)
      this.grade = item.name
      this.classInfo = '请选择'
      if (item.name === '三年级') {
        this.classItems = [{
          name: '1班',
          value: 1
        }, {
          name: '2班',
          value: 2
        }, {
          name: '3班',
          value: 3
        }, {
          name: '4班',
          value: 4
        }, {
          name: '5班',
          value: 5
        }, {
          name: '6班',
          value: 6
        }, {
          name: '7班',
          value: 7
        }, {
          name: '8班',
          value: 8
        }]
      } else if (item.name === '四年级') {
        this.classItems = [{
          name: '1班',
          value: 1
        }, {
          name: '2班',
          value: 2
        }, {
          name: '3班',
          value: 3
        }, {
          name: '4班',
          value: 4
        }, {
          name: '5班',
          value: 5
        }]
      }
    },

    chooseWeek (item) {
      const remain = parseInt(item.name.match(/\d+/)[0])
      const isMinus = item.name.indexOf('-') !== -1
      // 剩余名额有负数或者为零
      if (isMinus || remain <= 0) {
        return
      }

      this.week = item.name.slice(0, 3)

      this.time = '请选择'
      if (item.name === '周六' || item.name === '周日') {
        this.timeItems = [{
          name: '8:30-10:00',
          value: 1
        }, {
          name: '10:30-12:00',
          value: 2
        }, {
          name: '14:00-15:30',
          value: 3
        }, {
          name: '15:45-17:15',
          value: 4
        }, {
          name: '17:30-19:00',
          value: 5
        }]
      } else {
        this.timeItems = [{
          name: '17:30-19:00',
          value: 1
        }]
      }
    },

    pay () {
      const that = this
      const openid = localStorage.getItem('openid')
      if (this.grade === '五年级') {
        this.classInfo = '固定一个班级'
      }

      if (!openid) {
        this.show = true
        return
      }

      if (!this.name || this.phone.length !== 11 || this.grade === '请选择' || this.week === '请选择') {
        this.show = true
        return
      }

      // let timeRe = '放学后'
      // if (this.time === 1) {
      //   timeRe = '放学后'
      // } else if (this.time === 2) {
      //   timeRe = '放学后'
      // }
      axios({
        url: 'https://shop.actuedu.com/web/init_pay',
        method: 'post',
        data: {
          name: this.name,
          school: '红军小学2021',
          grade: this.grade,
          phone: this.phone,
          address: '无',
          date: '2021年3月份开始',
          time: this.week,
          openid
        }
      })
        .then(response => {
          console.log(response)
          const json = response.data && response.data.data
          console.log(json)
          window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
              'appId': json.appId,
              'timeStamp': json.timeStamp,
              'nonceStr': json.nonceStr,
              'package': json.package,
              'signType': 'MD5',
              'paySign': json.paySign
            },
            (res) => {
              if (res.err_msg === 'get_brand_wcpay_request:ok') {
                alert('支付成功,请稍后查询,正在保存订票信息，如有疑问,请联系管理员..')
                that.status = 2
                that.info = {
                  name: that.name,
                  grade: that.grade,
                  time: that.week
                }
              } else {
                console.log('关闭了微信支付')
              }
            })
        })
        .catch(e => {
          console.log(e)
        })
      // eslint-disable
    },

    cancelReserve () {
      const openid = localStorage.getItem('openid')
      if (!openid) {
        return
      }
      axios({
        url: 'https://shop.actuedu.com/web/cancel_reserve',
        method: 'post',
        data: {
          openid
        }
      })
        .then(response => {
          console.log(response)
          const isCancel = response.data && response.data.data
          if (isCancel === 1) {
            location.reload()
          }
        })
        .catch(e => {
          console.log(e)
        })
    },

    changeStatus (status) {
      const openid = localStorage.getItem('openid')
      if (status === 2 && !openid) {
        location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx94da9e1b3126380e&redirect_uri=https://shop.actuedu.com/hongjun&response_type=code&scope=snsapi_base&state=acshop#wechat_redirect'
        return
      }
      this.status = status
    }
  },
  created () {
    document.title = '3月份爱创课报名'
    const openid = localStorage.getItem('openid')
    const that = this
    axios({
      url: 'https://shop.actuedu.com/web/had_pay',
      method: 'post',
      data: {
        school: '红军小学2021',
        openid
      }
    })
      .then(response => {
        console.log(response)
        const json = response.data && response.data.data
        that.hadPay = json.had
        that.info = json
        console.log(json)
        if (json.had) {
          that.status = 2
        }
      })
      .catch(e => {
        console.log(e)
      })

    axios({
      url: 'https://shop.actuedu.com/web/get_reserve_count',
      method: 'post',
      data: {
      }
    })
      .then(response => {
        console.log(response)
        const count = response.data && response.data.count
        // that.info = json
        console.log('json: ', count)
        let weekItemsRe = []
        this.weekItems.forEach((item, i) => {
          // 80个
          if (item.name === '星期五') {
            item.remain = 80 - count.w5
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期一') {
            item.remain = 120 - count.w1
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期二') {
            item.remain = 120 - count.w2
            item.name = `${item.name}(剩余名额：${item.remain})`
          }
          // 120个
          if (item.name === '星期三') {
            item.remain = 120 - count.w3
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          // 120个
          if (item.name === '星期四') {
            item.remain = 120 - count.w4
            item.name = `${item.name}(剩余名额：${item.remain})`
          }

          weekItemsRe.push(item)
        })

        // console.log(this.weekItems)
        // this.weekItems = this.weekItems.filter(item => !item.is_over)
        this.weekItems = [...this.weekItemsRe]
      })
      .catch(e => {
        console.log(e)
      })

    axios({
      url: 'https://shop.actuedu.com/web/get_signature',
      method: 'post',
      data: {
        url: 'https://shop.actuedu.com/reserve'
      }
    })
      .then(response => {
        console.log(response)
        const data = response.data && response.data.data
        window.wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateTimelineShareData', 'updateAppMessageShareData']
        })

        window.wx.ready(function () {
          window.wx.updateAppMessageShareData({
            title: '3月份爱创课报名',
            desc: '开始报名啦',
            link: 'https://shop.actuedu.com/reserve',
            imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
            success: function () {
              // alert('成功')
            },
            fail: function () {
              // alert('失败')
            }
          })
          window.wx.onMenuShareAppMessage({
            title: '3月份爱创课报名',
            desc: '开始报名啦',
            link: 'https://shop.actuedu.com/reserve',
            imgUrl: 'https://jianchuapp.oss-cn-shenzhen.aliyuncs.com/static/logo.png',
            type: 'link',
            dataUrl: null,
            success: function () {
            // 用户点击了分享后执行的回调函数
            }
          })
        })
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  padding-bottom: 8vh;
}

.choose-item {
  width: 95vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  height: 7vh;
}

.img {
  width: 100vw;
  box-sizing: border-box;
}

.apply-box, .pay-box {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: fixed;
  bottom: 0;
  height: 9vh;
  border-top: 1px solid #f2f2f2;
}

.apply {
  width: 80vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
}

.pay {
  width: 60vw;
  box-sizing: border-box;
  background: #f76627;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 6vh;
  border-radius: 4px;
  margin-left: 2rem;
}

.nut-textinput {
  margin: 10px 0;
}

.item-box {
  display: flex;
  align-items: center;
}

.success {
  width: 6rem;
  height: 6rem;
  box-sizing: border-box;
  margin-top: 2rem;
}

.tp-box {
  display: flex;
  flex-wrap: wrap;
  width: 60vw;
  margin: 20px 0;
}
</style>
