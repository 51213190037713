<template>
  <div class="hello">
    <div @click="pay">支付</div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  },
  methods: {
    pay () {
      const json = { appId: 'wx94da9e1b3126380e',
        timeStamp: '1593543711',
        nonceStr: 'X8i8jOTR3smGGIb3',
        package: 'prepay_id=wx010301510248623d6fdd61a61083244400',
        signType: 'MD5',
        paySign: 'D350C5F6307F65AF0E411697F56FD2AD',
        timestamp: '1593543711' }
      // eslint-disable
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          'appId': json.appId,
          'timeStamp': json.timeStamp,
          'nonceStr': json.nonceStr,
          'package': json.package,
          'signType': 'MD5',
          'paySign': json.paySign
        },
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            alert('支付成功,请稍后查询,正在保存订票信息，如有疑问,请联系管理员..')
          } else {
            console.log('关闭了微信支付')
          }
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
